import { CalendarDate, parseAbsolute, toZoned, ZonedDateTime } from '@internationalized/date';
// eslint-disable-next-line import/no-unresolved
import { RangeValue } from '@react-types/shared';
import { isFunction } from 'lodash-es';
import { Calendar, CalendarCell, CalendarProps, DateValue } from 'react-aria-components';
import { CalendarGrid } from '../../atoms/calendar-grid/calendar-grid';
import { CalendarGridBody } from '../../atoms/calendar-grid-body/calendar-grid-body';
import { CalendarGridHeader } from '../../atoms/calendar-grid-header/calendar-grid-header';
import { CalendarHeader } from '../../atoms/calendar-header/calendar-header';
import { CalendarHeaderCell } from '../../atoms/calendar-header-cell/calendar-header-cell';

type SlimCalendarProps = Omit<
  CalendarProps<DateValue>,
  'defaultValue' | 'value' | 'onChange' | 'isDateUnavailable' | 'minValue' | 'maxValue'
>;

type IsDateUnavailableFn = (date: string) => boolean;

type OnChange = (value: string) => void;

interface _CalendarProps extends SlimCalendarProps {
  name?: string;
  onCalendarHeaderChange?: (visibleRange: RangeValue<CalendarDate>) => void;
  defaultValue?: string;
  value?: string;
  onChange?: OnChange;
  isDateUnavailable?: IsDateUnavailableFn;
  timezone: string;
  minValue?: string;
  maxValue?: string;
}

function _Calendar({
  onCalendarHeaderChange,
  defaultValue,
  value,
  minValue,
  maxValue,
  isDateUnavailable,
  onChange,
  timezone,
  ...props
}: _CalendarProps) {
  const computedIsDateUnavailable = isFunction(isDateUnavailable)
    ? (date: DateValue) => {
        if (date instanceof ZonedDateTime) {
          return isDateUnavailable(date.toAbsoluteString());
        } else {
          return isDateUnavailable(date.toString());
        }
      }
    : undefined;

  const computedOnChange = isFunction(onChange)
    ? (date: DateValue) => {
        if (date instanceof ZonedDateTime) {
          onChange(date.toAbsoluteString());
        } else {
          onChange(toZoned(date, timezone).toAbsoluteString());
        }
      }
    : undefined;

  return (
    <Calendar
      className="text-grey-900 flex w-fit flex-col gap-y-2 p-4"
      defaultValue={defaultValue ? parseAbsolute(defaultValue, timezone) : undefined}
      isDateUnavailable={computedIsDateUnavailable}
      maxValue={maxValue ? parseAbsolute(maxValue, timezone) : undefined}
      minValue={minValue ? parseAbsolute(minValue, timezone) : undefined}
      onChange={computedOnChange}
      value={value ? parseAbsolute(value, timezone) : undefined}
      {...props}>
      <CalendarHeader onChange={onCalendarHeaderChange} />
      <CalendarGrid>
        <CalendarGridHeader>
          {(day) => <CalendarHeaderCell>{day}</CalendarHeaderCell>}
        </CalendarGridHeader>
        <CalendarGridBody>
          {(date) => (
            <CalendarCell
              className="rac-selected:bg-grey-900 rac-focus:outline-none text-grey-900 rac-unavailable:text-grey-500 rac-unavailable:cursor-not-allowed rac-unavailable:!ring-0 rac-outside-visible-range:text-grey-500 rac-disabled:text-grey-500 rac-disabled:cursor-not-allowed rac-disabled:ring-0 rac-outside-visible-range:cursor-not-allowed text-p5 rac-selected:text-white rac-hover:ring-1 rac-hover:ring-grey-900 rac-selected:ring-0 flex h-10 w-10 items-center justify-center rounded-full font-medium"
              date={date}
            />
          )}
        </CalendarGridBody>
      </CalendarGrid>
    </Calendar>
  );
}

export { _Calendar as Calendar };
